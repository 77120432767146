import React, { useEffect, useState } from 'react';
import Fingerprint2 from 'fingerprintjs2';
import './App.css';

const App = () => {
    const [fingerprint, setFingerprint] = useState('');

    useEffect(() => {
        const getFingerprint = () => {
            Fingerprint2.get((components) => {
                // Получаем уникальный идентификатор
                const values = components.map(component => component.value);
                const uniqueFingerprint = Fingerprint2.x64hash128(values.join(''));
                setFingerprint(uniqueFingerprint);
               // console.log('Fingerprint:', uniqueFingerprint);
            });
        };

        getFingerprint();
    }, []);

    function getBrowserFingerprint() {
        // Получаем различные параметры
        const userAgent = navigator.userAgent; // User agent
        const language = navigator.language; // Язык браузера
        const screenResolution = `${window.screen.width}x${window.screen.height}`; // Разрешение экрана
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Часовой пояс
        const platform = navigator.platform; // Платформа (например, iOS, Windows)

        // Создаем строку для отпечатка
        const fingerprint = [
            userAgent,
            language,
            screenResolution,
            timezone,
            platform
        ].join('::'); // Объединяем параметры

        // Генерируем хеш отпечатка
        const hash = btoa(fingerprint); // Преобразуем в base64 для простоты

        return hash; // Возвращаем хеш отпечатка
    }

// Использование функции
    const fingerprint2 = getBrowserFingerprint();
    ///console.log('Fingerprint:', fingerprint2.substring(0, 32));

    return (
        <div style={{margin: '10px'}}>
            <h1>A unique browser fingerprint</h1>
            <p style={{fontSize: '18px', color: "blue", fontWeight: "bold"}}>fingerprintJS</p>
            <p>{fingerprint}</p>
            <p style={{fontSize: '18px', color: "blue", fontWeight: "bold"}}>My scripts</p>
            <p style={{wordWrap: 'break-word'}}>{fingerprint2}</p>
            <p style={{wordWrap: 'break-word'}}>{fingerprint2.replace(/[a-zA-Z]/g, '')
                .replace(/[^0-9:/.]/g, '')}</p>
        </div>
    );
};

export default App;
